<template>
  <div class="align-items-center mt-2">
    <!-- Pital Poin -->
    <div class="d-flex align-items-center mr-2 mt-1">
      <b-avatar
        variant="light-success"
        rounded
      >
        <feather-icon
          icon="DollarSignIcon"
          size="18"
        />
      </b-avatar>
      <div class="ml-1">
        <h5 class="mb-0 text-capitalize">
          Pital Poin
        </h5>
        <small>
          Balance: <strong>{{ talentData.wallets.poin | formatAmount }}</strong>
        </small>
      </div>
    </div>
    <!-- Dompet Hasil Usaha -->
    <div class="d-flex align-items-center mr-2 mt-1">
      <b-avatar
        variant="light-success"
        rounded
      >
        <feather-icon
          icon="DollarSignIcon"
          size="18"
        />
      </b-avatar>
      <div class="ml-1">
        <h5 class="mb-0 text-capitalize">
          Dompet Hasil Usaha
        </h5>
        <small>
          Balance: <strong>{{ talentData.wallets.business | formatAmount }}</strong>
        </small>
      </div>
    </div>
    <!-- Dompet Hasil Usaha -->
    <div class="d-flex align-items-center mr-2 mt-1">
      <b-avatar
        variant="light-success"
        rounded
      >
        <feather-icon
          icon="DollarSignIcon"
          size="18"
        />
      </b-avatar>
      <div class="ml-1">
        <h5 class="mb-0 text-capitalize">
          Pital Ads
        </h5>
        <small>
          Balance: <strong>{{ talentData.wallets.ads | formatAmount }}</strong>
        </small>
      </div>
    </div>
  </div>
</template>

<script>
import {
  BAvatar,
} from 'bootstrap-vue'

export default {
  components: {
    BAvatar,
  },
  props: {
    talentData: {
      type: Object,
    },
  },
}
</script>
