<template>
  <div>
    <div class="mb-2" v-for="(workdays, index) in talentData.workdays" :key="`workdays${index}`">
      <h6 class="mb-2">{{ workdays.day_name }}</h6>
      <app-timeline>
        <app-timeline-item
          v-for="(time, indexTime) in workdays.times" :key="`time${indexTime}`"
          :title="indexTime == 0 ? 'Waktu Mulai' : 'Waktu Selesai'"
          :subtitle="time.start_time"
          variant="success"
        />
      </app-timeline>
      <hr>
    </div>
  </div>
</template>

<script>
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'

export default {
  components: {
    AppTimeline,
    AppTimelineItem,
  },
  props: {
    talentData: {
      type: Object,
    },
  },
}
</script>
