<template>
  <table class="table b-table table-fixed">
    <thead>
      <tr>
        <th>Photo</th>
        <th>User</th>
        <th>Role</th>
        <th>Can Manage Business</th>
        <th>Can Manage Message</th>
        <th>Can Manage Transaction</th>
        <th>Can Manage Pital Ads</th>
      </tr>
    </thead>
    <tbody>
      <tr
        v-for="(team, index) in talentData.teams"
        :key="`team${index}`"
      >
        <td>
          <b-img
            v-if="team.user.photo_url != null"
            :src="team.user.photo_url"
            blank-color="#ccc"
            thumbnail
            fluid
            alt="placeholder"
          />
          <span v-else>-</span>
        </td>
        <td>
          <router-link :to="`/user-detail/${team.user.uuid}`">
            {{ team.user.name }}<br>
            <small>{{ team.user.email }}</small>
          </router-link>
        </td>
        <td>
          <span class="text-capitalize">
            {{ team.role }}
          </span>
        </td>
        <td>
          <span :class="team.can_manage_business == true ? 'text-success' : 'text-danger'">
            <feather-icon
              :icon="team.can_manage_business == true ? 'CheckCircleIcon' : 'XCircleIcon'"
              class="mr-75"
            />
          </span>
        </td>
        <td>
          <span :class="team.can_manage_message == true ? 'text-success' : 'text-danger'">
            <feather-icon
              :icon="team.can_manage_message == true ? 'CheckCircleIcon' : 'XCircleIcon'"
              class="mr-75"
            />
          </span>
        </td>
        <td>
          <span :class="team.can_manage_transaction == true ? 'text-success' : 'text-danger'">
            <feather-icon
              :icon="team.can_manage_transaction == true ? 'CheckCircleIcon' : 'XCircleIcon'"
              class="mr-75"
            />
          </span>
        </td>
        <td>
          <span :class="team.can_manage_pital_ads == true ? 'text-success' : 'text-danger'">
            <feather-icon
              :icon="team.can_manage_pital_ads == true ? 'CheckCircleIcon' : 'XCircleIcon'"
              class="mr-75"
            />
          </span>
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
import {
  BImg,
} from 'bootstrap-vue'

export default {
  components: {
    BImg,
  },
  props: {
    talentData: {
      type: Object,
    },
  },
}
</script>
