<template>
  <div>
    <b-dropdown
      variant="info"
      size="sm"
    >
      <template #button-content>
        Action
      </template>
      <b-dropdown-item
        v-if="checkPermission('show talent') && isDetail == true"
        @click="goToDetail(uuid)"
      >
        <feather-icon
          icon="EyeIcon"
          class="mr-75"
        />
        Detail Talent
      </b-dropdown-item>
      <b-dropdown-item
        v-if="checkPermission('approve talent') && status == 'pending'"
        @click="approveReject(uuid, 'approve')"
      >
        <feather-icon
          icon="CheckCircleIcon"
          class="mr-75 text-capitalize"
        />
        Approve Talenta
      </b-dropdown-item>
      <b-dropdown-item
        v-if="checkPermission('reject talent') && status == 'pending'"
        @click="approveReject(uuid, 'reject')"
      >
        <feather-icon
          icon="XCircleIcon"
          class="mr-75 text-capitalize"
        />
        Reject Talenta
      </b-dropdown-item>
      <b-dropdown-item
        v-if="checkPermission('delete talent')"
        @click="deleteItem(uuid)"
      >
        <feather-icon
          icon="TrashIcon"
          class="mr-75"
        />
        Delete Talenta
      </b-dropdown-item>
      <b-dropdown-item
        v-if="checkPermission('banned talent') && status == 'active' || status == 'banned'"
        @click="status == 'banned' ? bannedItem('unbanned', uuid) : bannedItem('banned', uuid)"
      >
        <feather-icon
          :icon="status == 'banned' ? 'UnlockIcon' : 'LockIcon'"
          class="mr-75"
        />
        {{ status == 'banned' ? 'Unbanned' : 'Banned' }} User
      </b-dropdown-item>
    </b-dropdown>
  </div>
</template>

<script>
import {
  BDropdown, BDropdownItem,
} from 'bootstrap-vue'
import { checkPermission, successNotification, errorNotification } from '@/auth/utils'

export default {
  components: {
    BDropdown,
    BDropdownItem,
  },
  /* eslint-disable vue/require-default-prop */
  props: {
    status: {
      type: String,
    },
    uuid: {
      type: String,
    },
    loadData: {
      type: Function,
    },
    isDetail: {
      type: Boolean,
    },
  },
  setup(props) {
    return {
      checkPermission,
      successNotification,
      errorNotification,
    }
  },
  methods: {
    approveReject(uuid, type) {
      this.$http.post(`admin/talents/${uuid}/${type}`)
        .then(response => {
          this.loadData()
          successNotification(this, 'Success', `Talent successfully ${type}`)
        })
        .catch(error => {
          if (error.response.data.meta.messages.length > 0) {
            errorNotification(this, 'Oops!', error.response.data.meta.messages)
          }
        })
    },
    bannedItem(type, id) {
      this.$swal({
        title: 'Are you sure?',
        text: `Are you sure to ${type} this talent?`,
        icon: 'warning',
        showCancelButton: true,
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.$http.post(`admin/talents/${id}/${type}`)
            .then(response => {
              this.loadData()
              this.$swal({
                icon: 'success',
                title: 'Success!',
                text: `Talent successfully ${type}`,
                customClass: {
                  confirmButton: 'btn btn-success',
                },
              })
            })
            .catch(error => {
              if (error.response.data.meta.messages.length > 0) {
                errorNotification(this, 'Oops!', error.response.data.meta.messages)
              }
            })
        }
      })
    },
    deleteItem(id) {
      this.$swal({
        title: 'Are you sure?',
        text: 'Are you sure to delete this talent?',
        icon: 'warning',
        showCancelButton: true,
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.$http.delete(`admin/talents/${id}`)
            .then(response => {
              this.$swal({
                icon: 'success',
                title: 'Success!',
                text: 'Talent successfully deleted',
                customClass: {
                  confirmButton: 'btn btn-success',
                },
              })
              this.$router.push({ name: 'talenta.index' })
            })
            .catch(error => {
              if (error.response.data.meta.messages.length > 0) {
                errorNotification(this, 'Oops!', error.response.data.meta.messages)
              }
            })
        }
      })
    },
    goToDetail(id) {
      this.$router.push({ name: 'talenta.show', params: { id } })
    },
  },
}
</script>
