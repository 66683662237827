<template>
  <div v-if="talentData">
    <b-card>
      <b-row>
        <!-- User Info: Left col -->
        <b-col
          cols="12"
          xl="6"
          class="d-flex justify-content-between flex-column"
        >
          <!-- User Avatar & Action Buttons -->
          <div class="d-flex justify-content-start">
            <b-avatar
              :src="talentData.image"
              :text="talentData.name != null ? talentData.name.charAt(0) : '-'"
              :variant="`light-danger`"
              size="104px"
              rounded
            />
            <div class="d-flex flex-column ml-1">
              <div class="mb-1">
                <h4>
                  {{ talentData.name != null ? talentData.name : '-' }}
                </h4>
                <span class="card-text badge badge-light-primary text-capitalize">Status: {{ talentData.status != null ? talentData.status : '-' }}</span>
              </div>
              <table class="mt-2 mt-xl-0 w-100">
                <tr>
                  <th>
                    <span class="font-weight-bold">Nomor Telopon</span>
                  </th>
                  <td>
                    {{ talentData.phone_number != null ? talentData.phone_number : "-" }}
                  </td>
                </tr>
                <tr>
                  <th>
                    <span class="font-weight-bold">Nama KTP</span>
                  </th>
                  <td>
                    {{ talentData.ktp_name != null ? talentData.ktp_name : "-" }}
                  </td>
                </tr>
                <tr>
                  <th>
                    <span class="font-weight-bold">Kota</span>
                  </th>
                  <td>
                    {{ talentData.city ? talentData.city.name : '-' }}
                  </td>
                </tr>
                <tr>
                  <th>
                    <span class="font-weight-bold">Alamat</span>
                  </th>
                  <td>
                    {{ talentData.address ? talentData.address : '-' }}
                  </td>
                </tr>
                <tr>
                  <th>
                    <span class="font-weight-bold">NPWP</span>
                  </th>
                  <td>
                    {{ talentData.npwp != null ? talentData.npwp : "-" }}
                  </td>
                </tr>
                <tr>
                  <th>
                    <span class="font-weight-bold">Badan Hukum</span>
                  </th>
                  <td>
                    <span :class="talentData.is_corporate == true ? 'text-success' : 'text-danger'">
                      <feather-icon
                        :icon="talentData.is_corporate == true ? 'CheckCircleIcon' : 'XCircleIcon'"
                        class="mr-75"
                      />
                    </span>
                  </td>
                </tr>
                <tr>
                  <th>
                    <span class="font-weight-bold">Terdaftar pada</span>
                  </th>
                  <td>
                    {{ talentData.register_at != null ? talentData.register_at : "-" }}
                  </td>
                </tr>
                <tr>
                  <th>
                    <span class="font-weight-bold">Deskripsi</span>
                  </th>
                  <td>
                    {{ talentData.description != null ? talentData.description : "-" }}
                  </td>
                </tr>
              </table>
              <div class="d-flex flex-wrap">
                <!-- Action -->
                <action-talenta
                  :load-data="loadData"
                  :uuid="talentData.uuid"
                  :status="talentData.status"
                  :is-detail="false"
                />
              </div>
            </div>
          </div>
          <!-- Wallet -->
          <BalanceWalletTalenta :talent-data="talentData" />
        </b-col>
        <b-col
          cols="12"
          xl="6"
        >
          <b-tabs
            vertical
            start
            nav-class="nav-left"
            nav-wrapper-class="nav-vertical"
          >
            <b-tab title="Photo KTP">
              <b-img
                v-if="talentData.ktp_photo != null"
                :src="talentData.ktp_photo"
                blank-color="#ccc"
                class="w-100"
                alt="ktp photo"
                thumbnail
                fluid
              />
              <span v-else>-</span>
            </b-tab>
            <b-tab title="Selfie Photo">
              <b-row>
                <b-col
                  v-for="(item, index) in talentData.selfie_photos"
                  :key="`selfie${index}`"
                >
                  <b-img
                    :src="item.selfie_style.image"
                    blank-color="#ccc"
                    class="w-100"
                    alt="selfie photo"
                    thumbnail
                    fluid
                  />
                  <b-img
                    :src="item.image"
                    blank-color="#ccc"
                    class="w-100"
                    alt="selfie photo"
                    thumbnail
                    fluid
                  />
                </b-col>
              </b-row>
              <!-- <span v-else>-</span> -->
            </b-tab>
            <b-tab
              v-if="talentData.is_corporate == true"
              title="SIUP Usaha"
            >
              <b-img
                v-if="talentData.corporate_siup != null"
                :src="talentData.corporate_siup"
                blank-color="#ccc"
                class="w-100"
                alt="siup"
                thumbnail
                fluid
              />
              <span v-else>-</span>
            </b-tab>
            <b-tab
              v-if="talentData.is_corporate == true"
              title="TDP Usaha"
            >
              <b-img
                v-if="talentData.corporate_tdp != null"
                :src="talentData.corporate_tdp"
                blank-color="#ccc"
                class="w-100"
                alt="tdp"
                thumbnail
                fluid
              />
              <span v-else>-</span>
            </b-tab>
            <b-tab
              v-if="talentData.is_corporate == true"
              title="NPWP Perusahaan"
            >
              <b-img
                v-if="talentData.corporate_npwp != null"
                :src="talentData.corporate_npwp"
                blank-color="#ccc"
                class="w-100"
                alt="npwp"
                thumbnail
                fluid
              />
              <span v-else>-</span>
            </b-tab>
          </b-tabs>
        </b-col>
      </b-row>
    </b-card>
    <b-card>
      <b-tabs content-class="pt-1">
        <b-tab
          title="Teams"
          active
        >
          <TeamTalent :talent-data="talentData" />
        </b-tab>
        <b-tab
          title="Jam Kerja"
        >
          <DaysWork :talent-data="talentData" />
        </b-tab>
        <b-tab
          title="Pital Poin Histories"
          @click="typeSlug = 'poin'"
        >
          <TransactionHistories
            :result="result"
            :type-slug="'poin'"
            :search-filter="false"
            :user-uuid="id"
            :is-loading="isLoading"
            :get-data="getData"
            :type-user-or-talents="'talents'"
          />
        </b-tab>
        <b-tab
          title="Pital Ads Kredit Histories"
          @click="typeSlug = 'ads'"
        >
          <TransactionHistories
            :result="result"
            :type-slug="'ads'"
            :search-filter="false"
            :user-uuid="id"
            :is-loading="isLoading"
            :get-data="getData"
            :type-user-or-talents="'talents'"
          />
        </b-tab>
        <b-tab
          title="Dompet Hasil Usaha Histories"
          @click="typeSlug = 'business'"
        >
          <TransactionHistories
            :result="result"
            :type-slug="'business'"
            :search-filter="false"
            :user-uuid="id"
            :is-loading="isLoading"
            :get-data="getData"
            :type-user-or-talents="'talents'"
          />
        </b-tab>
      </b-tabs>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BAvatar,
  BRow,
  BCol,
  BTabs,
  BTab,
  BImg,
} from 'bootstrap-vue'
import {
  checkPermission,
  successNotification,
  errorNotification,
} from '@/auth/utils'
import Vue from 'vue'
import VuejsDialog from 'vuejs-dialog'
import 'vuejs-dialog/dist/vuejs-dialog.min.css'

import ActionTalenta from '@/components/Talenta/ActionTalenta.vue'
import TeamTalent from '@/components/Talenta/TeamTalent.vue'
import DaysWork from '@/components/Talenta/DaysWork.vue'
import BalanceWalletTalenta from '@/components/Talenta/BalanceWalletTalenta.vue'
import TransactionHistories from '@/components/Wallet/TransactionHistories.vue'

Vue.use(VuejsDialog)
export default {
  title() {
    return 'Detail Talenta'
  },
  components: {
    BCard,
    BRow,
    BCol,
    BAvatar,
    BTabs,
    BTab,
    BImg,
    ActionTalenta,
    TransactionHistories,
    TeamTalent,
    DaysWork,
    BalanceWalletTalenta,
  },
  data() {
    return {
      id: this.$route.params.id,
      talentData: null,
      isLoading: false,
      typeSlug: 'poin',
      result: {},
      filter: {
        talent_uuid: '',
        page: 1,
      },
    }
  },
  watch: {
    '$route.params.id': function (value) {
      this.id = value
      this.loadData()
    },
    typeSlug() {
      this.getData()
    },
  },
  setup(props) {
    return {
      checkPermission,
      successNotification,
      errorNotification,
    }
  },
  created() {
    this.loadData()
    this.getData()
  },
  methods: {
    loadData() {
      this.$http.get(`/admin/talents/${this.id}`).then(response => {
        this.talentData = response.data.data
      })
    },
    getData(page = 1) {
      this.isLoading = true
      const queryParams = this.filter
      queryParams.page = page
      queryParams.talent_uuid = this.id

      this.$http.get(`/admin/wallet/${this.typeSlug}`, {
        params: queryParams,
      })
        .then(response => {
          this.result = response.data.data
          this.isLoading = false
        })
    },
  },
}
</script>
